export const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();

  return /iphone|ipad|ipod/.test(userAgent);
};

export const isInStandaloneMode = () => {
  return ("standalone" in (window).navigator && (window).navigator.standalone);
};

export const isSeznam = () => !!navigator.userAgent.match(/.*SznProhlizec.*/);
export const isSafari = () => !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/) && !isSeznam() && !isFirefox() && !isChrome();
export const isChrome = () => !!navigator.userAgent.match('CriOS');
export const isFirefox = () => !!navigator.userAgent.match("FxiOS");
